/* eslint-disable no-undef */
Dribbble.TeaserStats = {
  init(shots) {
    // Set and reveal teaser stats on shot lists
    const $shots = $('#main ol.dribbbles>li');

    $(shots).each((index, shot) => {
      const shotLi = $(`ol.dribbbles li#screenshot-${shot.id}`);

      if (shot.rebounds_count > 0) {
        shotLi.find('.has-rebounds').html(numberWithDelimiter(shot.rebounds_count)).closest('a').show();
      } else if (shot.is_rebound) {
        shotLi.find('.is-rebound').closest('a').show();
      }
      if (shot.attachments_count > 0) {
        shotLi.find('.attachments-mark').show();
      }

      shot.likeCount = new Dribbble.TeaserStats.LikeCount(shot, shotLi);
      shot.likeCount.render();
    });

    $shots.find('.js-shot-details-container').css({ visibility: 'visible' });
  },
};

Dribbble.TeaserStats.LikeCount = function (shot, $shotLi) {
  let $likeTeaserLi;

  return {
    initialized: false,

    init() {
      if (this.initialized) {
        return;
      }

      $likeTeaserLi = $shotLi.find('.js-shot-likes-container');

      // TODO: this should be moved / re-thought since the like button trigger
      // isn't part of the teaser stats anymore.
      if (!User.loggedIn()) {
        /// TODO: fix this
        $shotLi.find('a.like-shot').attr({
          'data-signup-trigger': '',
          'data-context': 'like-shot',
        });
      }

      this.initialized = true;
    },

    render() {
      this.init();

      // set the total
      const $shotLikesCount = $likeTeaserLi.find('.js-shot-likes-count');
      $shotLikesCount.html(numberWithDelimiter(shot.likes_count));

      // set or remove our liked indicator class
      $likeTeaserLi.toggleClass('marked', shot.liked);
    },
  };
};

// if we have shots ready to be used, handle them now
if (typeof (newShots) !== 'undefined') {
  Dribbble.Shots.add(newShots);
  Dribbble.TeaserStats.init(newShots);
}
